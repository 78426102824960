import Vue from 'vue';

import { PersistKeys } from '../../core/store/plugins/persistence';

/**
 * Module namespace
 */
export const NAMESPACE = 'applications';

/**
 * Removes app details
 */
export const REMOVE_APP_DETAILS = 'REMOVE_APP_DETAILS';
/**
 * Clears all app details
 */
export const CLEAR_ALL_APPS = 'CLEAR_ALL_APPS';

/**
 * Sets app details
 */
export const SET_APP_DETAILS = 'SET_APP_DETAILS';

/**
 * App details key prefix in state
 */
const APP_STATE_KEY_PREFIX = 'app_details_';

/**
 *
 * @param {string} appAlias - the app alias
 * @returns the app details key
 */
const getAppStateKey = (appAlias) => `${APP_STATE_KEY_PREFIX}${appAlias}`;

/**
 * @returns new module state
 */
const getNewState = () => ({
  [PersistKeys.PRESERVE_STATE_ON_CLEAR]: true,
  [PersistKeys.MODULE_VERSION]: 1,
});

/**
 * App details store module
 */
export const ApplicationsModule = {
  namespaced: true,
  state: { ...getNewState() },

  getters: {
    getAppDetails: (state) => (appAlias) => {
      return state[getAppStateKey(appAlias)];
    },
  },

  mutations: {
    /**
     * Sets the app's details as a property of
     * the state under the appAlias key
     *
     * @param {object} state - Store state
     * @param {object} payload - mutation payload
     * @param {string} payload.appAlias - the key for the details
     * @param {object} payload.value - the state to save
     */
    [SET_APP_DETAILS](state, { appAlias, value }) {
      const appStateKey = getAppStateKey(appAlias);

      // We need to use use Vue.set because we are adding
      // a property to the root state, and root property additions
      // are not reactive
      Vue.set(state, appStateKey, value);
    },
    /**
     * Removes an app's details
     *
     * @param {object} state - Store state
     * @param {object} payload - mutation payload
     * @param {string} payload.appAlias - the key for the details
     */
    [REMOVE_APP_DETAILS](state, { appAlias }) {
      const appStateKey = getAppStateKey(appAlias);

      Vue.delete(state, appStateKey);
    },
    /**
     * Clears all categories
     *
     * @param {object} state - Store State
     */
    [CLEAR_ALL_APPS](state) {
      Object.keys(state).forEach((key) => {
        if (key.startsWith(APP_STATE_KEY_PREFIX)) {
          Vue.delete(state, key);
        }
      });
    },
  },
};

import 'date-input-polyfill';
import './static/style/app.scss';

import PiivoUI from 'piivo-ui/src';
import VueCookie from 'vue-cookie';
import VueResource from 'vue-resource';
import VueRouter from 'vue-router';
import Vuelidate from 'vuelidate';

import Auth from '../core/authentication';
import { permission } from '../core/directives/permission';
import extensionPoints, { registerExtensionPoint } from '../core/extensionPoints';
import * as filters from '../core/filters';
import helpers from '../core/helpers';
import HelperManager from '../core/helpers/plugin';
import i18n from '../core/i18n';
import router from '../core/router';
import services from '../core/services';
import { AppInfoService } from '../core/services/appInfo';
import { LanguagesManagerService } from '../core/services/languagesManagerService';
import store from '../core/store';
import { PersistKeys } from '../core/store/plugins/persistence';
import components from './components';
import { track } from './directives/track';
import { layouts } from './layouts';
import PlatformRoutes from './routes';
import { AlertsService } from './services/alertsService';
import { AnalyticsService } from './services/analyticsService';
import { ApplicationsService } from './services/applicationsService';
import { DashboardWidgetsManager } from './services/dashboardWidgetsManager';
import { ModuleManager } from './services/moduleManager';
import { NotificationsService } from './services/notificationsService';
import alerts from './store/alerts';
import { ApplicationsModule, NAMESPACE as APPLICATIONS_NAMESPACE } from './store/applications';
import { NotificationsModule } from './store/notifications';
import http from './utils/http';

const VuePortal = require('@linusborg/vue-simple-portal');

/**
 * Function to install module in Vue instance
 * @params {Object} Vue: Vue instance
 * @params {Object} settings: object who contains router and store instance
 * */
export function install(Vue) {
  // Install core plugins
  Vue.use(VueRouter);
  Vue.use(VueResource);
  Vue.use(Vuelidate);
  Vue.use(VueCookie);
  Vue.use(HelperManager);
  Vue.use(VuePortal.default, {
    name: 'portal',
  });
  Vue.use(http);

  // Install custom plugins
  Vue.use(Auth);
  Vue.use(PiivoUI);

  // Install directives
  Vue.directive('track', track);
  Vue.directive('permission', permission);

  // Install filters
  Object.keys(filters).forEach((key) => {
    Vue.filter(key, filters[key]);
  });

  const platformExtension = {
    store,
    router,
    services,
    helpers,
    extensionPoints,
    i18n,
    dashboardWidgetsManager: new DashboardWidgetsManager(),
    /**
     * Dynamically registers a store module, and intelligently
     * preserves state with version
     *
     * @param {string} namespace - module namespace
     * @param {*} module - store module
     */
    registerStoreModule(namespace, module) {
      // Preserve state only if the module can handle it and either no version, or versions do match
      const preserveState =
        module.state[PersistKeys.PRESERVE_STATE_ON_CLEAR] &&
        !!platformExtension.store.state[namespace] &&
        (!Object.hasOwnProperty.call(module.state, PersistKeys.MODULE_VERSION) ||
          module.state[PersistKeys.MODULE_VERSION] ===
            platformExtension.store.state[namespace][PersistKeys.MODULE_VERSION]);

      platformExtension.store.registerModule(namespace, module, {
        preserveState,
      });
    },
  };

  // Create injector point
  registerExtensionPoint('platform', platformExtension);

  platformExtension.router.addRoutes(PlatformRoutes);
  platformExtension.registerStoreModule('alerts', alerts.AlertsModule);
  platformExtension.registerStoreModule('notifications', NotificationsModule);

  platformExtension.registerStoreModule(APPLICATIONS_NAMESPACE, ApplicationsModule);

  components.forEach((component) => {
    Vue.component(component.name, component);
  });
  layouts.forEach((component) => {
    Vue.component(component.name, component);
  });

  const languagesManagerService = new LanguagesManagerService();
  // Make sure the languages are loading before app is rendered since they are always loaded when
  // the app is loaded, even if the state might have been restored
  languagesManagerService.setLoadingLanguages(true);

  const analyticsService = new AnalyticsService();
  analyticsService.start();

  /* Install services */
  platformExtension.services.addService('appInfo', new AppInfoService());
  platformExtension.services.addService('languages', languagesManagerService);
  platformExtension.services.addService('alerts', new AlertsService(platformExtension.store));
  platformExtension.services.addService('notifications', new NotificationsService());
  platformExtension.services.addService('analytics', analyticsService);
  platformExtension.services.addService(
    'applications',
    new ApplicationsService(platformExtension.store)
  );
  platformExtension.services.addService('moduleManager', new ModuleManager(platformExtension));

  return platformExtension;
}

<template>
  <pui-drop-zone-with-preview v-bind="$attrs" :addFilesMessage="addFilesMessage" v-on="$listeners">
    <!-- pass slots to child -->
    <template v-for="(_, slotIndex) of $scopedSlots" #[slotIndex]="slotProps">
      <slot :name="slotIndex" v-bind="slotProps" />
    </template>

    <template #filesSummary="{ files }">
      <i18n path="common.overrides.drop_zone.summary">
        <template #count>{{ files.length }}</template>
        <template #size>
          {{ totalSize(files) }}
        </template>
      </i18n>
    </template>

    <template #fileMetadata="{ file }">
      {{ fileMetadata(file) }}
    </template>
  </pui-drop-zone-with-preview>
</template>

<script>
import { convertByteToMo, getExtension } from '../../../../core/helpers/fileHelpers';

export default {
  name: 'PuiCommonDropZoneWithPreview',
  computed: {
    /**
     * @returns {string} message for the 'add files' button
     */
    addFilesMessage() {
      return this.$t('common.overrides.drop_zone.message');
    },
  },
  methods: {
    /**
     * @param {object[]} files - the files in the drop zone
     * @returns {string} message for the total size of the files
     */
    totalSize(files) {
      const mbSize = convertByteToMo(files.reduce((acc, file) => acc + file.size, 0));
      return this.$t('common.file.size_mb', { size: mbSize });
    },
    /**
     * @param {object} file - file in the drop zone
     * @returns {string} message for the metadata of the file
     */
    fileMetadata(file) {
      const fileMbSize = convertByteToMo(file.size);
      const extension = getExtension(file.name);

      return this.$t('common.overrides.drop_zone.file.metadata', {
        size: this.$t('common.file.size_mb', { size: fileMbSize }),
        extension,
      });
    },
  },
};
</script>

export default {
  install(Vue) {
    if (!Vue.http) {
      if (process.env.NODE_ENV === 'development') {
        throw new Error('Please use VueResource before adding platform http helpers');
      } else {
        console.warn('Please use VueResource before adding platform http helpers');
      }
    }
    Vue.http.isCancel = (err) =>
      // Navigator.onLine might be true if the pc cannot
      // to an external point, but can connect to LAN
      // for Chrome and Safari
      // TODO: find more robust method ?
      err.status === 0 && navigator.onLine;
  },
};

<template>
  <component :is="tagName" id="evo-app">
    <div class="pui-wrapper">
      <pui-common-spinner
        v-if="showAppSpinner"
        :message="loadingMessage"
        fullscreen
      ></pui-common-spinner>
      <router-view v-else />
    </div>
  </component>
</template>

<script>
import { piivoUiConfigKey } from 'piivo-ui/src';

import i18n from '../../core/i18n';
import services from '../../core/services';
import keys from '../piivo-ui/keys.json';

export default {
  name: 'PlatformRoot',
  props: {
    /**
     * The tag to render
     */
    tagName: {
      type: String,
      default: 'div',
    },
  },
  provide: {
    /**
     * Provides piivo-ui config
     */
    [piivoUiConfigKey]: {
      /**
       * Provides the $pui_t translation method
       */
      $pui_t: (puiKey, ...rest) => {
        const internalKey = keys[puiKey];
        return internalKey ? i18n.t(internalKey, ...rest) : puiKey;
      },
    },
  },
  computed: {
    /**
     * @returns {boolean} if the languages are loading
     */
    isLoadingLanguages() {
      return services.getService('languages').getLoadingLanguages();
    },
    /**
     * @returns {boolean} if the languages failed to load
     */
    hasLanguagesLoadError() {
      return services.getService('languages').getLoadError();
    },
    /**
     * @returns {boolean} if modules are loading
     */
    isLoadingModules() {
      return services.getService('moduleManager').getIsLoadingModules();
    },
    /**
     * @returns {boolean} if the global app spinner should be shown
     */
    showAppSpinner() {
      return this.isLoadingLanguages || this.isLoadingModules;
    },
    /**
     * @returns {string} the global app loading message
     */
    loadingMessage() {
      return this.isLoadingLanguages || this.hasLanguagesLoadError
        ? 'Loading...'
        : this.$t('platform.app.loading');
    },
  },
  /**
   * Component mounted hook
   */
  mounted() {
    services.onRootAppMount();
  },
};
</script>

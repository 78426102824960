import Vue from 'vue';

import { ResolvedResponse } from '../types/http';
import { Label } from '../types/i18n';

/**
 * App info base
 */
const API_APP_INFO_BASE = '/api/piivo/v1/appInfo';

/**
 * Piivo language
 */
export interface Language extends Label {
  identifier: string;
  createdDate: string;
  updatesDate: string;
  paths: string;
  itemId: string;
  alias: string;
}

/**
 * App info
 */
export interface AppInfoDto {
  appInfo: {
    version: string;
    configurationVersion: string;
  };
  languages: Language[];
}

export default {
  /**
   * Gets app info
   *
   * @returns the app info
   */
  async getAppInfo(): Promise<AppInfoDto> {
    const res = (await Vue.http.get(API_APP_INFO_BASE)) as ResolvedResponse<AppInfoDto>;
    return res.body;
  },
};

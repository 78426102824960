export const CREATE_NOTIFICATION = 'CREATE_NOTIFICATION';
const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const UPDATE_NOTIFICATION = 'UPDATE_NOTIFICATION';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';
export const CLEAR_NOTIFICATIONS = 'CLEAR_NOTIFICATIONS';

const notificationIdGenerator = (function* () {
  let id = 1;
  while (true) {
    yield id++;
  }
})();

/**
 * Notifications store module.
 */
export const NotificationsModule = {
  state: {
    notifications: [],
  },

  getters: {
    /**
     * @returns {object[]} the notifications in reverse order that they were added
     */
    notifications: (state) => [...state.notifications].reverse(),
  },

  actions: {
    /**
     * Creates a notification and adds it the the state
     *
     * @param {object} context - the store context
     * @param {Function} context.commit - The store commit function
     * @param {object} notification - the notification object
     * @param {string} notification.componentName - the name of the component to render the notification
     * @param {object} notification.data - data to pass to the component that will render the notification
     */
    [CREATE_NOTIFICATION]({ commit }, notification) {
      const notificationId = notificationIdGenerator.next().value;
      const notificationStoreObj = {
        ...notification,
        id: notificationId,
      };

      commit(ADD_NOTIFICATION, notificationStoreObj);

      return notificationId;
    },
  },

  mutations: {
    /**
     * Adds a notification to the state
     *
     * @param {Object} state - Store state
     * @param {object} notification - the notification object
     * @param {Number} notification.id - the id of the notification
     * @param {string} notification.componentName - the name of the component to render the notification
     * @param {object} notification.data - data to pass to the component that will render the notification
     */
    [ADD_NOTIFICATION](state, notification) {
      state.notifications.push(notification);
    },
    /**
     * Updates the notification with the given id and data
     *
     * @param {Object} state - Store state
     * @param {object} notification - the commit object
     * @param {Number} notification.id - the id of the notification too update
     * @param {object} notification.data - data to merge into the old data
     */
    [UPDATE_NOTIFICATION](state, { id, data }) {
      const oldNotificationObj = state.notifications.find((notif) => notif.id === id);
      if (oldNotificationObj) {
        oldNotificationObj.data = {
          ...oldNotificationObj.data,
          ...data,
        };
      }
    },
    /**
     * Removes the notification with the given id
     *
     * @param {Object} state - Store state
     * @param {Number} notificationId - notification ID
     */
    [REMOVE_NOTIFICATION](state, notificationId) {
      for (let i = 0; i < state.notifications.length; i++) {
        if (notificationId === state.notifications[i].id) {
          state.notifications.splice(i, 1);
          break;
        }
      }
    },

    /**
     * Clears all notifications
     *
     * @param {Object} state - Store State
     */
    [CLEAR_NOTIFICATIONS](state) {
      state.notifications = [];
    },
  },
};

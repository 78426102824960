import Vue from 'vue';

import store from '../../core/store';
import { NotificationEvents } from '../constants/notifications';
import {
  CLEAR_NOTIFICATIONS,
  CREATE_NOTIFICATION,
  REMOVE_NOTIFICATION,
  UPDATE_NOTIFICATION,
} from '../store/notifications';
import { INotificationsService } from './types';

const eventBus = new Vue();

/**
 * Notifications Service
 */
export class NotificationsService implements INotificationsService {
  /**
   * Adds a notification
   *
   * @param componentName - the component that render the the notification
   * @param  data - the data to send to the notification
   * @returns the ID for the created notification
   */
  async createNotification(componentName: string, data: any): Promise<number> {
    const notificationId = (await store.dispatch(CREATE_NOTIFICATION, {
      componentName,
      data,
    })) as Promise<number>;

    return notificationId;
  }

  /**
   * Updates a notification
   *
   * @param notificationId - the ID of the notfication to update
   * @param data - the data to merge into the old notification data
   */
  updateNotification(notificationId: number, data: any): void {
    store.commit(UPDATE_NOTIFICATION, { id: notificationId, data });
  }

  /**
   * Removes the notification with the given id
   *
   * @param notificationId - the notification to remove
   */
  removeNotification(notificationId: number): void {
    store.commit(REMOVE_NOTIFICATION, notificationId);
  }

  /**
   * Clears all notifications
   */
  clearNotification(): void {
    store.commit(CLEAR_NOTIFICATIONS);
  }

  /**
   * Emits an event
   *
   * @param eventName - the event to emit
   * @param args - arguments to pass to listeners
   */
  private _$emit(eventName: string, ...args: unknown[]): void {
    eventBus.$emit(eventName, ...args);
  }

  /**
   * Attaches a listener
   *
   * @param event - the event to listen to
   * @param callback - the callback to invoke when the event is received
   */
  $on(event: string | string[], callback: () => void): void {
    eventBus.$on(event, callback);
  }

  /**
   * Removes a listener
   *
   * @param event - the event to remove all callbacks,
   * or the given callback for
   * @param callback - callback to remove
   */
  $off(event: string | string[], callback: () => void): void {
    eventBus.$off(event, callback);
  }

  /**
   * Emits the event to open the notifications
   */
  openNotifications(): void {
    this._$emit(NotificationEvents.OPEN_NOTIFICATIONS);
  }
}

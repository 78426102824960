<template>
  <pui-flex
    class="plat-dashboard plat-dashboard__root"
    :flex="dashboard ? 1 : null"
    justifyContent="center"
  >
    <pui-flex v-if="loading">
      <pui-spinner class="plat-dashboard__spinner"></pui-spinner>
    </pui-flex>
    <pui-error v-else-if="error" class="plat-dashboard__error">
      <i18n path="platform.dashboard.error.primary"></i18n>
      <i18n path="platform.dashboard.error.secondary">
        <template #btnReload>
          <span class="pui-error__btn-reload" @click="getDashboard">
            {{ $t('platform.dashboard.error.btnReload') }}
          </span>
        </template>
      </i18n>
    </pui-error>
    <div v-else-if="dashboard" ref="grid" class="plat-dashboard__grid grid-stack">
      <component
        :is="getWidget(element.type)"
        v-for="element in dashboard.elements"
        :key="element.id"
        :ref="`widget_${element.id}`"
        :widget="element"
        :layout="dashboard.layout[element.alias]"
        class="plat-dashboard__widget"
      ></component>
    </div>
  </pui-flex>
</template>

<script>
import 'gridstack/dist/gridstack.min.css';

import { GridStack } from 'gridstack';

import { getDashboardWidgetsManager } from '../../services/dashboardWidgetsManager';

export default {
  name: 'PlatDashboard',
  props: {
    loadDashboard: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      error: false,
      dashboard: null,
      grid: null,
    };
  },
  methods: {
    getWidget(name) {
      return getDashboardWidgetsManager().getWidget(name);
    },
    async init() {
      await this.$nextTick();
      this.grid = GridStack.init(
        {
          cellHeight: 15,
          cellHeightUnit: 'rem',
          column: 12,
          acceptWidgets: true,
          margin: '10px',
        },
        this.$refs.grid
      );
    },
    async getDashboard() {
      this.loading = true;
      this.error = false;
      try {
        this.dashboard = await this.loadDashboard();
        if (this.dashboard) {
          this.init();
        }
      } catch (err) {
        console.error(err);
        this.error = true;
      }
      this.loading = false;
    },
  },
  mounted() {
    this.getDashboard();
  },
};
</script>

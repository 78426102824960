<template>
  <div
    class="grid-stack-item plat-dashboard-widget plat-dashboard-widget__root"
    v-bind="gridStackAttributes"
  >
    <div class="grid-stack-item-content plat-dashboard-widget__content"><slot></slot></div>
  </div>
</template>

<script>
import widgetMixin from './widgetMixin';

export default {
  name: 'PlatDashboardWidgetBase',
  mixins: [widgetMixin],
  computed: {
    /**
     * @returns {object} attributes for the gridstack library to pick up when
     * it initializes the grid
     */
    gridStackAttributes() {
      return {
        id: this.widget.id,
        'gs-id': this.widget.id,
        'gs-x': this.layout.x || 0,
        'gs-y': this.layout.y || 0,
        'gs-w': this.layout.width || 1,
        'gs-h': this.layout.height || 1,
      };
    },
  },
};
</script>

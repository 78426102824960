import services from '../../core/services';

/**
 * Directive to track click events.
 * Value {String} => Event name.
 * Arg => Specify the event to track (click event by default).
 */
export const track = {
  bind(el, binding, vnode) {
    if (el && binding && binding.value) {
      // Get event to track in arg
      const eventToTrack = binding.arg || DEFAULT_TRACK_EVENT;

      // Keep track function reference in element
      el._trackFunc = trackEvent.bind(this, binding.value);

      // Add event listener to track event on click
      el.addEventListener(eventToTrack, el._trackFunc, true);
    }
  },
  unbind(el, binding) {
    // Get event to track in arg
    const eventToTrack = binding.arg || DEFAULT_TRACK_EVENT;

    // Remove click event listener
    el.removeEventListener(eventToTrack, el._trackFunc);

    // Remove track function reference in element
    delete el._trackFunc;
  },
};

// Default track event
const DEFAULT_TRACK_EVENT = 'click';

/**
 * Track event function
 * @param {String} eventName
 * @private
 */
const trackEvent = (eventName) => {
  services.getService('analytics').trackEvent(eventName);
};

<template>
  <pui-progress-linear
    v-bind="$attrs"
    :message="$attrs.message || $t('common.overrides.progress.loading')"
    v-on="$listeners"
  >
    <!-- pass slots to child -->
    <template v-for="(_, slotIndex) of $scopedSlots" #[slotIndex]="slotProps">
      <slot :name="slotIndex" v-bind="slotProps" />
    </template>
  </pui-progress-linear>
</template>

<script>
export default {
  name: 'PuiCommonProgressLinear',
};
</script>

import Vue from 'vue';
import { Store } from 'vuex';

import i18n from '../../core/i18n';
import router from '../../core/router';
import services from '../../core/services';
import { USER_LOGOUT } from '../../core/store/modules/coreModule';
import api from '../api/applications';
import {
  CLEAR_ALL_APPS,
  NAMESPACE as APPLICATIONS_NAMESPACE,
  REMOVE_APP_DETAILS,
  SET_APP_DETAILS,
} from '../store/applications';
import { ApplicationTypes } from '../types/applications';
import {
  IAlertsService,
  IApplicationsService,
  ModuleApplication,
  PiivoApplicationLifecycleService,
} from './types';

/**
 * Application
 */
interface ApplicationState {
  /**
   * Loading the app's details
   */
  loadingDetails: boolean;
  /**
   * Loading the apps services
   */
  loadingServices: boolean;
  /**
   * The services namespace
   */
  servicesNamespace: string;
}

/**
 * Service state
 */
interface State {
  /**
   * Is loading applications list
   */
  isLoadingAllApplications: boolean;
  /**
   * List of applications
   */
  applications: ApplicationTypes.Application[] | null;
  /**
   * Application details map
   */
  registry: Record<string, ApplicationState | undefined>;
  /**
   * The current app alias
   */
  currentAppAlias: string | null;
}

/**
 * Default state
 */
const DEFAULT_STATE: State = {
  isLoadingAllApplications: true,
  applications: null,
  registry: {},
  currentAppAlias: null,
};

/**
 * @returns new state
 */
function getNewState(): State {
  return JSON.parse(JSON.stringify(DEFAULT_STATE)) as State;
}

/**
 * Applications Service
 */
export class ApplicationsService implements IApplicationsService {
  /**
   * Reactive state
   */
  state = Vue.observable(getNewState());

  public constructor(private readonly store: Store<unknown>) {
    store.subscribeAction((action) => {
      // Reset apps and app details on logout since they depend on the user
      if (action.type === USER_LOGOUT) {
        this.clearCache();
      }
    });
  }

  /**
   * @inheritdoc
   */
  public isLoadingAllApplications(): boolean {
    return this.state.isLoadingAllApplications;
  }

  /**
   * @inheritdoc
   */
  public async getAllApplications(): Promise<ApplicationTypes.Application[]> {
    try {
      if (this.state.applications) {
        return this.state.applications;
      }

      this.state.isLoadingAllApplications = true;

      const res = await api.getAllApplications();
      this.state.applications = res.body;
      return this.state.applications;
    } catch (err) {
      services
        .getService<IAlertsService>('alerts')
        ?.alertError(i18n.t('platform.applications.error.retrieve_applications') as string);
      throw err;
    } finally {
      this.state.isLoadingAllApplications = false;
    }
  }

  /**
   * @inheritdoc
   */
  public async getApplication(
    id: string,
    alias: string
  ): Promise<ApplicationTypes.ApplicationDetails | null> {
    const application = this.state.registry[alias];
    if (!application) {
      throw new Error(`Application ${alias} not registered`);
    }

    const existingAppDetails = this.getAppDetails(alias);
    if (existingAppDetails) {
      application.loadingDetails = false;
      return existingAppDetails;
    }

    application.loadingDetails = true;

    try {
      const res = await api.getApplicationById(id);
      const applicationDetails = res.body;

      application.loadingDetails = false;
      this.store.commit(`${APPLICATIONS_NAMESPACE}/${SET_APP_DETAILS}`, {
        appAlias: alias,
        value: applicationDetails,
      });

      return applicationDetails;
    } catch (err) {
      this.store.commit(`${APPLICATIONS_NAMESPACE}/${REMOVE_APP_DETAILS}`, {
        appAlias: alias,
      });
      services
        .getService<IAlertsService>('alerts')
        ?.alertError(i18n.t('platform.applications.error.retrieve_application_data') as string);

      void router.push({ name: 'settingsError' });
      throw err;
    }
  }

  /**
   * @inheritdoc
   */
  public getIsLoadingApp(alias: string): boolean {
    const appState = this.state.registry[alias];
    if (appState) {
      return appState.loadingDetails || appState.loadingServices;
    }

    return true;
  }

  /**
   * @inheritdoc
   */
  public getAppDetails(alias: string): ApplicationTypes.ApplicationDetails | null {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    return this.store.getters[`${APPLICATIONS_NAMESPACE}/getAppDetails`](alias) ?? null;
  }

  /**
   * @inheritdoc
   */
  public getAppParameter<T = unknown>(
    appAlias: string,
    parameterAlias: string,
    parseJSON?: boolean
  ): T | null | undefined {
    const appDetails = this.getAppDetails(appAlias);
    if (!appDetails || !appDetails.parameters) {
      return null;
    }
    const param = appDetails.parameters.find((param) => param.alias === parameterAlias);
    if (!param) {
      return null;
    }

    if (parseJSON) {
      return JSON.parse(param.value) as T;
    }
    return param.value as unknown as T | null;
  }

  /**
   * @inheritdoc
   */
  public getAppDeepParameter<T = unknown>(
    appAlias: string,
    parameterAlias: string,
    settingPath: string,
    parseJSON?: boolean
  ): T | null | undefined | Error {
    // Error as return type forces the caller to check the type
    const appParameter = this.getAppParameter(appAlias, parameterAlias, parseJSON);
    if (!appParameter) {
      return new Error(`App parameter ${parameterAlias} did not exist`);
    }

    if (!settingPath) {
      return appParameter as T | null | undefined;
    }

    const paths = settingPath.split('.');
    let path: string | number | undefined = paths.shift();
    let setting = appParameter as T | Array<T> | Record<string, T>;

    while (path !== undefined && setting !== null && setting !== undefined) {
      if (Array.isArray(setting) || (setting && typeof setting === 'object')) {
        setting = (setting as Record<string, T>)[path];
      } else {
        return new Error(`Could not get module setting at path ${settingPath}`);
      }
      path = paths.shift();
    }

    return setting as T | null | undefined;
  }

  /**
   * @inheritdoc
   */
  public getCurrentApp(): ApplicationTypes.ApplicationDetails | null {
    return this.state.currentAppAlias ? this.getAppDetails(this.state.currentAppAlias) : null;
  }

  // APP LIFECYCLE

  /**
   * App before create callback.
   * Loads the corresponding Piivo app details and calls service setup methods
   *
   * @param appAlias - the alias of the mounted app module
   */
  public async onApplicationBeforeCreate(appAlias: string): Promise<void> {
    const application = this.state.registry[appAlias];
    if (!application) {
      throw new Error(`Application ${appAlias} not registered`);
    }

    application.loadingServices = true;

    // Load the application (requires loading apps list to get app id)
    // only if we don't already have its details
    if (!this.getAppDetails(appAlias)) {
      const allApplications = await this.getAllApplications();
      const appDetails = allApplications.find((a) => a.alias === appAlias);
      if (!appDetails) {
        throw new Error(`Application ${appAlias} not found in list of application`);
      }

      this.state.currentAppAlias = appAlias;
      await this.getApplication(appDetails.itemId, appDetails.alias);
    } else {
      application.loadingDetails = false;
    }

    const nsServices = services.getNamespaceServices<PiivoApplicationLifecycleService>(
      application.servicesNamespace
    );
    for (const service of nsServices) {
      await service.onApplicationMount?.();
    }

    application.loadingServices = false;
  }

  /**
   * App before destroyed callback.
   * Calls service teardown methods
   *
   * @param appAlias - the alias of the mounted app module
   */
  public onApplicationBeforeDestroyed(appAlias: string): void {
    const registeredApp = this.state.registry[appAlias];
    if (!registeredApp) {
      return;
    }

    const nsServices = services.getNamespaceServices<PiivoApplicationLifecycleService>(
      registeredApp.servicesNamespace
    );
    for (const service of nsServices) {
      // Do not wait for methods because there shouldn't be a teardown order
      void service.onBeforeApplicationDestroyed?.();
    }
  }

  /**
   * @inheritdoc
   */
  public registerApplication(application: ModuleApplication): void {
    Vue.set<ApplicationState>(this.state.registry, application.alias, {
      loadingDetails: true,
      loadingServices: true,
      servicesNamespace: application.servicesNamespace,
    });

    // Initialize store value if doesn't exist
    if (!this.getAppDetails(application.alias)) {
      this.store.commit(`${APPLICATIONS_NAMESPACE}/${SET_APP_DETAILS}`, {
        appAlias: application.alias,
        value: null,
      });
    }
  }

  /**
   * @inheritdoc
   */
  public clearCache(): void {
    this.state.isLoadingAllApplications = true;
    this.state.applications = null;
    this.store.commit(`${APPLICATIONS_NAMESPACE}/${CLEAR_ALL_APPS}`);
  }
}

import puiActionBar from './ActionBar.vue';
import puiAuthorizedRouterLink from './AuthorizedRouterLink';
import platDashboard from './dashboard/Dashboard';
import platDashboardWidgetBase from './dashboard/WidgetBase';
import puiHeader from './Header.vue';
import puiHeaderButton from './HeaderButton.vue';
import puiHeaderButtonList from './HeaderButtonList.vue';
import puiHeaderButtonSeparator from './HeaderButtonSeparator.vue';
import puiNotifications from './notifications/Notifications.vue';
import puiCommonDropZone from './overrides/dragNDrop/DropZone';
import puiCommonDropZoneWithPreview from './overrides/dragNDrop/DropZoneWithPreview';
import puiCommonInputTag from './overrides/forms/InputTag';
import puiCommonSelect from './overrides/forms/Select';
import puiCommonProgressLinear from './overrides/progress/ProgressLinear';
import puiCommonSpinner from './overrides/progress/Spinner';
import platformRoot from './PlatformRoot';
import puiSidebar from './Sidebar.vue';
import puiSidebarItem from './SidebarItem.vue';

export {
  platformRoot as platform,
  puiHeader,
  puiHeaderButton,
  puiHeaderButtonSeparator,
  puiHeaderButtonList,
  puiSidebar,
  puiSidebarItem,
  puiActionBar,
  puiNotifications,
  puiAuthorizedRouterLink,
  platDashboard,
  platDashboardWidgetBase,
  puiCommonDropZone,
  puiCommonDropZoneWithPreview,
  puiCommonInputTag,
  puiCommonSelect,
  puiCommonProgressLinear,
  puiCommonSpinner,
};

export default [
  platformRoot,
  puiHeader,
  puiHeaderButton,
  puiHeaderButtonSeparator,
  puiHeaderButtonList,
  puiSidebar,
  puiSidebarItem,
  puiActionBar,
  puiNotifications,
  puiAuthorizedRouterLink,
  platDashboard,
  platDashboardWidgetBase,
  puiCommonDropZone,
  puiCommonDropZoneWithPreview,
  puiCommonInputTag,
  puiCommonSelect,
  puiCommonProgressLinear,
  puiCommonSpinner,
];

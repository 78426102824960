import Vue from 'vue';

import { IPlatformExtension } from '../extensionPoints/types';
import { IApplicationsService, IModuleManager, ModuleConfiguration } from './types';

export class ModuleManager implements IModuleManager {
  /**
   * List of registered app modules
   */
  appModules: ModuleConfiguration[] = [];
  /**
   * The platform extension
   */
  platformExtension: IPlatformExtension;

  /**
   * Reactive state
   */
  state = Vue.observable({ isLoadingModules: true });

  constructor(platformExtension: IPlatformExtension) {
    this.platformExtension = platformExtension;
  }

  /**
   * @inheritdoc
   */
  public registerModule(module: ModuleConfiguration): void {
    this.appModules.push(module);

    if (module.application) {
      this.platformExtension.services
        .getService<IApplicationsService>('applications')
        .registerApplication(module.application);
    }
  }

  /**
   * @inheritdoc
   */
  public async loadAll(useCache = true): Promise<void> {
    for (const mod of this.appModules) {
      await mod.install(this.platformExtension, useCache);
    }
  }

  /**
   * @inheritdoc
   */
  public setLoadingModules(loading: boolean): void {
    this.state.isLoadingModules = loading;
  }

  /**
   * @inheritdoc
   */
  public getIsLoadingModules(): boolean {
    return this.state.isLoadingModules;
  }
}

<template>
  <pui-input-tag ref="inputTag" v-bind="$attrs" v-on="$listeners">
    <!-- pass slots to child -->
    <template v-for="(_, slotIndex) of $scopedSlots" #[slotIndex]="slotProps">
      <slot :name="slotIndex" v-bind="slotProps" />
    </template>

    <template v-if="!$slots.simpleNoItems" #simpleNoItems="{ itemLabel }">
      {{ $t('common.overrides.input_tag.no_items', { itemLabel }) }}
    </template>

    <template v-if="!$slots.minItems" #minItems="{ minItems, itemLabel }">
      <div class="pui-input-message">
        <i class="mdi mdi-hand-left" />
        {{ $t('common.overrides.input_tag.min_items', { minItems, itemLabel }) }}
      </div>
    </template>

    <template v-if="!$slots.maxItems" #maxItems="{ maxItems, itemLabel }">
      <div class="pui-input-message">
        <i class="mdi mdi-hand-left" />
        {{ $t('common.overrides.input_tag.max_items', { maxItems, itemLabel }) }}
      </div>
    </template>

    <template
      v-if="!$slots.hiddenItemsCount && !$scopedSlots.hiddenItemsCount"
      #hiddenItemsCount="{ hiddenItemsCount }"
    >
      {{
        hiddenItemsCount
          ? $t('common.overrides.input_tag.hiddenItemsCount', { hiddenItemsCount })
          : null
      }}
    </template>
  </pui-input-tag>
</template>

<script>
export default {
  name: 'PuiCommonInputTag',
  methods: {
    // Forward methods
    getFocusedItemIndex(...args) {
      return this.$refs.inputTag.getFocusedItemIndex(...args);
    },
    focus(...args) {
      return this.$refs.inputTag.focus(...args);
    },
    focusItem(...args) {
      return this.$refs.inputTag.focusItem(...args);
    },
  },
};
</script>

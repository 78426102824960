<template>
  <plat-dashboard-widget-base :widget="widget" :layout="layout">
    <component :is="tableWidget" :widget="widget" :layout="layout"></component>
  </plat-dashboard-widget-base>
</template>

<script>
import { getDashboardWidgetsManager } from '../../../services/dashboardWidgetsManager';
import widgetMixin from '../widgetMixin';

export default {
  name: 'PlatDashboardSearchTable',
  mixins: [widgetMixin],
  computed: {
    /**
     * @returns {VueConstructor} the widget component for the table type
     */
    tableWidget() {
      return getDashboardWidgetsManager().getWidget(
        `${this.widget.type}/${this.widget.configuration.tableType}`
      );
    },
  },
};
</script>

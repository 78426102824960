<template>
  <li class="pui-header-button pui-header-button__root" v-on="$listeners">
    <a class="pui-header-button__link">
      <span v-if="picto" :class="{ 'pui-header-button__picto mdi': true, [picto]: !!picto }" />
      <span class="pui-header-button__label">
        <slot></slot>
      </span>
    </a>
  </li>
</template>

<script>
export default {
  name: 'PuiHeaderButton',
  props: {
    /**
     * Name of the icon
     */
    picto: {
      type: String,
      default: '',
    },
  },
};
</script>

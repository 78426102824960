<template>
  <plat-dashboard-widget-base :widget="widget" :layout="layout">
    <pui-flex class="plat-widget-shortcut plat-widget-shortcut__root" direction="column">
      <pui-flex class="plat-widget-shortcut__primary-container">
        <pui-flex
          :flex="widget.configuration.image ? '50%' : 1"
          class="plat-widget-shortcut__meta"
          direction="column"
        >
          <div class="plat-widget-shortcut__header">
            <span
              v-if="widget.configuration.titlePicto"
              :class="{
                'poster-dashboard-widget__icon plat-dashboard-widget__icon mdi': true,
                [widget.configuration.titlePicto]: !!widget.configuration.titlePicto,
              }"
            />
            <span
              v-if="widget.configuration.title"
              class="poster-dashboard-widget__title plat-dashboard-widget__title"
            >
              {{ piivoTranslateLabel(widget.configuration.title) }}
            </span>
          </div>
          <p v-if="widget.configuration.subtitle" class="plat-widget-shortcut__subtitle">
            {{ piivoTranslateLabel(widget.configuration.subtitle) }}
          </p>
        </pui-flex>
        <pui-flex
          v-if="widget.configuration.image"
          class="plat-widget-shortcut__image"
          justifyContent="center"
          alignItems="center"
          flex="50%"
        >
          <img :src="widget.configuration.image" />
        </pui-flex>
      </pui-flex>
      <pui-flex v-if="widget.configuration.url" class="plat-widget-shortcut__action-container">
        <div class="plat-widget-shortcut__action">
          <a v-if="widget.configuration.url.startsWith('http')" :href="widget.configuration.url">
            <pui-button variant="primary">
              {{ piivoTranslateLabel(widget.configuration.urlLabel) }}
            </pui-button>
          </a>
          <router-link v-else :to="widget.configuration.url">
            <pui-button variant="primary">
              {{ piivoTranslateLabel(widget.configuration.urlLabel) }}
            </pui-button>
          </router-link>
        </div>
      </pui-flex>
    </pui-flex>
  </plat-dashboard-widget-base>
</template>

<script>
import widgetMixin from './widgetMixin';

export default {
  name: 'PlatDashboardShortcut',
  mixins: [widgetMixin],
};
</script>

<template>
  <pui-select ref="select" v-bind="$attrs" v-on="$listeners">
    <!-- pass slots to child -->
    <template v-for="(_, slotIndex) of $scopedSlots" #[slotIndex]="slotProps">
      <slot :name="slotIndex" v-bind="slotProps" />
    </template>

    <!-- Add slots if we, ourself are not overridden -->
    <template v-if="!$slots.maxElements" #maxElements="{ max }">
      {{ $t('common.overrides.select.max', { max }) }}
    </template>
    <template v-if="!$slots.noOptions" #noOptions>
      {{ $t('common.overrides.select.no_options') }}
    </template>
  </pui-select>
</template>

<script>
export default {
  name: 'PuiCommonSelect',
  methods: {
    // EXPOSED
    /**
     * Blur element if exists
     */
    blur() {
      if (this.$refs.select && this.$refs.select.blur) {
        this.$refs.select.blur();
      }
    },
    /**
     * Focus element if exists
     */
    focus() {
      if (this.$refs.select && this.$refs.select.focus) {
        this.$refs.select.focus();
      }
    },
    /**
     * Get selected items
     */
    getSelectedItems() {
      if (this.$refs.select && this.$refs.select.getSelectedItems) {
        return this.$refs.select.getSelectedItems();
      }
      return [];
    },
    /**
     * Resets selection
     */
    resetSelection() {
      if (this.$refs.select && this.$refs.select.resetSelection) {
        return this.$refs.select.resetSelection();
      }
      return [];
    },
  },
};
</script>

import { version as localVersion } from '../../../package.json';
import appInfoApi from '../api/appInfo';
import store from '../store';
import { NAMESPACE as VERSIONS_NAMESPACE, SET_VERSIONS } from '../store/modules/versions';
import { AppInfo, IAppInfoService, VersionInfo } from './types';

export class AppInfoService implements IAppInfoService {
  /**
   * Previous versions state
   */
  prevVersions: Partial<VersionInfo> = this.getVersionInfo();

  /**
   * @inheritdoc
   */
  public async loadAppInfo(): Promise<AppInfo> {
    const res = await appInfoApi.getAppInfo();

    const versions = {
      localVersion,
      version: res.appInfo.version,
      configurationVersion: res.appInfo.configurationVersion,
    };
    store.commit(`${VERSIONS_NAMESPACE}/${SET_VERSIONS}`, versions);

    const versionUpgraded = this.appWasUpgraded(versions);
    // After versions are compared, overwrite old versions
    this.prevVersions = this.getVersionInfo();

    return {
      versions,
      languages: res.languages,
      versionUpgraded,
    };
  }

  /**
   * @inheritdoc
   */
  public getVersionInfo(): VersionInfo {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access
    return store.getters[`${VERSIONS_NAMESPACE}/versions`];
  }

  /**
   * Compares all versions
   *
   * @param currentVersions - the current versions
   * @returns true if any of the current versions is an upgrade
   */
  private appWasUpgraded(currentVersions: VersionInfo): boolean {
    if (!this.prevVersions.localVersion) {
      // The app must have been upgraded if no version was previously recorded
      return true;
    }

    return (
      this.versionChanged(this.prevVersions.localVersion, currentVersions.localVersion) ||
      this.versionChanged(this.prevVersions.version, currentVersions.version) ||
      this.versionChanged(
        this.prevVersions.configurationVersion,
        currentVersions.configurationVersion
      )
    );
  }

  /**
   * Compares the versions
   *
   * @param {string|null} prevVersion - the previous version
   * @param {string} nextVersion - the next version
   * @returns true if the old version was a valid value and the
   * next version is different
   */
  private versionChanged(prevVersion: string | undefined, nextVersion: string): boolean {
    return !!prevVersion && prevVersion !== nextVersion;
  }
}

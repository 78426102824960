var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('pui-input-tag',_vm._g(_vm._b({ref:"inputTag",scopedSlots:_vm._u([_vm._l((_vm.$scopedSlots),function(_,slotIndex){return {key:slotIndex,fn:function(slotProps){return [_vm._t(slotIndex,null,null,slotProps)]}}}),(!_vm.$slots.simpleNoItems)?{key:"simpleNoItems",fn:function(ref){
var itemLabel = ref.itemLabel;
return [_vm._v(" "+_vm._s(_vm.$t('common.overrides.input_tag.no_items', { itemLabel: itemLabel }))+" ")]}}:null,(!_vm.$slots.minItems)?{key:"minItems",fn:function(ref){
var minItems = ref.minItems;
var itemLabel = ref.itemLabel;
return [_c('div',{staticClass:"pui-input-message"},[_c('i',{staticClass:"mdi mdi-hand-left"}),_vm._v(" "+_vm._s(_vm.$t('common.overrides.input_tag.min_items', { minItems: minItems, itemLabel: itemLabel }))+" ")])]}}:null,(!_vm.$slots.maxItems)?{key:"maxItems",fn:function(ref){
var maxItems = ref.maxItems;
var itemLabel = ref.itemLabel;
return [_c('div',{staticClass:"pui-input-message"},[_c('i',{staticClass:"mdi mdi-hand-left"}),_vm._v(" "+_vm._s(_vm.$t('common.overrides.input_tag.max_items', { maxItems: maxItems, itemLabel: itemLabel }))+" ")])]}}:null,(!_vm.$slots.hiddenItemsCount && !_vm.$scopedSlots.hiddenItemsCount)?{key:"hiddenItemsCount",fn:function(ref){
var hiddenItemsCount = ref.hiddenItemsCount;
return [_vm._v(" "+_vm._s(hiddenItemsCount ? _vm.$t('common.overrides.input_tag.hiddenItemsCount', { hiddenItemsCount: hiddenItemsCount }) : null)+" ")]}}:null],null,true)},'pui-input-tag',_vm.$attrs,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }
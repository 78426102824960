import services from '../services';

/**
 * Create a comment node for element.
 * @private
 */
const _createCommentNode = (el, vnode) => {
  const comment = document.createComment(' ');
  Object.defineProperty(comment, 'setAttribute', { value: () => undefined });

  vnode.elm = comment;
  vnode.text = ' ';
  vnode.isComment = true;
  vnode.context = undefined;
  vnode.tag = undefined;
  vnode.data.directives = undefined;

  if (vnode.componentInstance) {
    vnode.componentInstance.$el = comment;
  }

  if (el.parentNode) {
    el.parentNode.replaceChild(comment, el);
  }
};

/**
 * Directive to check user permissions : Show or hide element according user permissions.
 * Value {String} => the permission to check.
 * Modifier 'disable' => disable element instead of hiding it.
 * Modifier 'undisplay' => change style to hide it instead of render with a comment node by default (infinite loop issue with table component).
 */
export const permission = {
  bind(el, binding, vnode) {
    if (el && vnode) {
      // Check user permission
      const hasPermission =
        vnode.context &&
        services.getService('auth') &&
        services.getService('auth').hasPermission(binding.value);
      if (hasPermission === false) {
        if (binding.modifiers.disable) {
          // Disable element
          el.disabled = true;
        } else if (binding.modifiers.undisplay) {
          // Change style element to hide it
          el.style.display = 'none';
        } else {
          // Create a comment node for element to hide it
          // Wait for element to be inserted in parent
          // https://github.com/vuejs/vue/issues/2887
          vnode.context.$nextTick(() => {
            _createCommentNode(el, vnode);
          });
        }
      }
    }
  },
};

import Vue from 'vue';

import { Response } from '../../core/types/http';
import { ApplicationTypes } from '../types/applications';

/**
 * Applications API.
 */
const API_URL_BASE = '/api/piivo/applications/';

export default {
  /**
   * Get all applications.
   * @returns all applications.
   */
  getAllApplications(): Response<ApplicationTypes.Application[]> {
    return Vue.http.get(`${API_URL_BASE}`) as Response<ApplicationTypes.Application[]>;
  },
  /**
   * Get an application by id.
   * @param id Application Id
   * @returns the corresponding application.
   */
  getApplicationById(id: string): Response<ApplicationTypes.ApplicationDetails> {
    return Vue.http.get(`${API_URL_BASE}${id}`) as Response<ApplicationTypes.ApplicationDetails>;
  },
};

<template>
  <ul class="pui-header__button-list">
    <slot />
  </ul>
</template>

<script>
export default {
  name: 'PuiHeaderButtonList',
};
</script>

<template>
  <pui-flex class="pui-action-bar pui-action-bar__root" alignItems="center">
    <div class="pui-action-bar__action-left"><slot name="left"></slot></div>
    <pui-flex class="pui-action-bar__action-title" alignItems="center"
      ><slot name="title"></slot
    ></pui-flex>
    <div class="pui-action-bar__action-right"><slot name="right"></slot></div>
  </pui-flex>
</template>

<script>
export default {
  name: 'PuiActionBar',
};
</script>

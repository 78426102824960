<template>
  <div class="pui-header-button-separator pui-header-button-separator__root">
    <span class="pui-header-button-separator__separator"> </span>
  </div>
</template>

<script>
export default {
  name: 'PuiHeaderButtonSeparator',
};
</script>

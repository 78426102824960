<template>
  <span>
    <pui-popper
      ref="popper"
      v-bind="$props"
      :options="computedOptions"
      popperClass="pui-notifications-popper"
    >
      <div class="pui-notifications">
        <pui-flex :class="headerClass" alignItems="center">
          {{ $t('platform.notifications.title') }}
          <pui-button flat variant="secondary" class="header-button-hide" @click="hide">
            {{ $t('platform.notifications.hide') }}
          </pui-button>
        </pui-flex>
        <pui-flex class="pui-notifications-list" direction="column">
          <component
            :is="notification.componentName"
            v-for="notification in notifications"
            :id="notification.id"
            :key="notification.id"
            :data="notification.data"
            @openDialog="openDialog"
          />
        </pui-flex>
      </div>
    </pui-popper>
    <component
      :is="dialogComponent"
      v-if="dialogComponent"
      ref="dialog"
      v-bind="dialogData"
      @close="onCloseDialog"
    />
  </span>
</template>

<script>
import puiPopper from 'piivo-ui/src/components/popper/Popper';
import { mapGetters } from 'vuex';

const defaultOptions = {
  placement: 'bottom-end',
};

export default {
  name: 'PuiNotifications',
  props: {
    ...puiPopper.props,
  },
  data() {
    return {
      dialogComponent: null,
      dialogData: {},
    };
  },
  computed: {
    ...mapGetters({
      // Retrieve notifications from store
      notifications: 'notifications',
    }),
    /**
     * @returns {object} class object for the header
     */
    headerClass() {
      return {
        'pui-notifications-header': true,
        showBottomBorder: !!this.notifications.length,
      };
    },
    /**
     * @returns {object} prop options merged with defaults
     */
    computedOptions() {
      return {
        ...defaultOptions,
        ...this.options,
      };
    },
  },
  methods: {
    /**
     * Opens the popper
     *
     * @returns {void}
     */
    open() {
      this.$refs.popper.open();
    },
    /**
     * Closes the popper
     *
     * @returns {void}
     */
    hide() {
      this.$refs.popper.close();
    },
    /**
     * Sets the current dialog component to the one
     * in parameter
     *
     * @param {string} dialogComponent - the name of the component
     * to render
     * @param {object} dialogData - object of props to pass to the
     * component
     */
    openDialog(dialogComponent, dialogData) {
      this.dialogComponent = dialogComponent;
      this.dialogData = dialogData;
    },
    /**
     * Clears the current dialog component
     *
     * @returns {void}
     */
    onCloseDialog() {
      this.dialogComponent = null;
      this.dialogData = {};
    },
  },
};
</script>

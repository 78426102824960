<template>
  <router-link v-if="hasPermission" :to="to" v-bind="$attrs" v-on="$listeners">
    <slot></slot>
  </router-link>
</template>

<script>
import services from '../../core/services';

export default {
  name: 'PuiAuthorizedRouterLink',
  props: {
    /**
     * The route to link to
     */
    to: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      route: {},
      routePermission: null,
      hasPermission: false,
    };
  },
  methods: {
    /**
     * Checks if we can access the route
     */
    checkPermission() {
      // Get route and permission with the route
      if (this.to == null) {
        return;
      }
      const routerResolution = this.$router.resolve(this.to);
      if (routerResolution == null) {
        return;
      }

      this.route = routerResolution.location;
      this.routePermission =
        routerResolution.route && routerResolution.route.meta
          ? routerResolution.route.meta.permission
          : null;
      this.hasPermission =
        !this.routePermission || services.getService('auth').hasPermission(this.routePermission);
    },
  },
  /**
   * Component mounted hook
   */
  mounted() {
    this.checkPermission();
  },
};
</script>

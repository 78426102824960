import Vue from 'vue';

import App from './App.vue';
import { install } from './platform';

// Disable useless tip
Vue.config.productionTip = false;

// Install the main module
const platform = install(Vue);

// Render the vue app
new Vue({
  // eslint-disable-line
  el: '#evo-app',
  // Attach the Vue instance to the window,
  // so it's available globally.
  created: function () {
    window.Vue = Vue;
  },
  render: (h) => h(App),
  // Pass router instance to vue components
  router: platform.router,
  // Pass store instance to vue components
  store: platform.store,
  // Pass i18n instance to vue components
  i18n: platform.i18n,
}).$mount('#evo-app');

<template>
  <pui-drop-zone v-bind="$attrs" :addFilesMessage="addFilesMessage" v-on="$listeners">
    <!-- pass slots to child -->
    <template v-for="(_, slotIndex) of $scopedSlots" #[slotIndex]="slotProps">
      <slot :name="slotIndex" v-bind="slotProps" />
    </template>

    <template #filesSummary="{ files }">
      <i18n path="common.overrides.drop_zone.summary">
        <template #size>
          {{ totalSize(files) }}
        </template>
      </i18n>
    </template>
  </pui-drop-zone>
</template>

<script>
import { convertByteToMo } from '../../../../core/helpers/fileHelpers';

export default {
  name: 'PuiCommonDropZone',
  computed: {
    /**
     * @returns {string} message for the 'add files' button
     */
    addFilesMessage() {
      return this.$t('common.overrides.drop_zone.message');
    },
  },
  methods: {
    /**
     * @param {object[]} files - the files in the drop zone
     * @returns {string} message for the total size of the files
     */
    totalSize(files) {
      return convertByteToMo(files.reduce((acc, file) => acc + file.size, 0));
    },
  },
};
</script>

// Tracking constants (event/metric names)
export default {
  // Authentication
  AUTH_LOGIN: 'platform.authentication.login',
  AUTH_LOGIN_SSO: 'signages.authentication.loginsso',
  AUTH_LOGOUT: 'signages.authentication.logout',

  // Applications
  APPS_SELECTION: 'platform.applications.selection',
};

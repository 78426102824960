import Vue from 'vue';

import { Response } from '../types/http';

/**
 * I18n base
 */
export const I18N_BASE = '/i18n';

export default {
  /**
   *
   * @param locale - the locale whose messages to retrieve
   */
  getLocaleMessages(locale: string): Response<object> {
    return Vue.http.get(`${I18N_BASE}/${locale}.json`) as Response<object>;
  },
};

<template>
  <nav class="pui-app-sidebar pui-app-sidebar__root">
    <ul class="pui-app-sidebar__list">
      <slot></slot>
    </ul>
  </nav>
</template>
<script>
export default {
  name: 'PuiSidebar',
};
</script>

import { VueConstructor } from 'vue';

import { getExtensionPoint } from '../../core/extensionPoints';
import DashboardShortcut from '../components/dashboard/DashboardShortcut.vue';
import DashboardPivotTable from '../components/dashboard/table/DashboardPivotTable.vue';
import DashboardSearchTable from '../components/dashboard/table/DashboardSearchTable.vue';
import { IDashboardWidgetsManager } from './types';

/**
 * Dashboard widget manager
 */
export class DashboardWidgetsManager implements IDashboardWidgetsManager {
  private registeredWidgets = new Map<string, any>();

  constructor() {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    this.registerWidget('Platform.Shortcut', DashboardShortcut as any);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    this.registerWidget('Platform.SearchTable', DashboardSearchTable as any);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    this.registerWidget('Platform.SearchTable/PivotTable', DashboardPivotTable as any);
  }

  /** @inheritdoc */
  registerWidget(name: string, widget: VueConstructor): void {
    this.registeredWidgets.set(name, widget);
  }

  /** @inheritdoc */
  getWidget(name: string): VueConstructor | undefined {
    return this.registeredWidgets.get(name) as VueConstructor | undefined;
  }

  /** @inheritdoc */
  getRegisteredWidgetNames(): string[] {
    return [...this.registeredWidgets.keys()];
  }
}

/**
 *
 * @returns the current dashboard widget manager in the extension point
 */
export function getDashboardWidgetsManager(): IDashboardWidgetsManager {
  return getExtensionPoint('platform').dashboardWidgetsManager as IDashboardWidgetsManager;
}

import { ApplicationInsights } from '@microsoft/applicationinsights-web';

import { IAnalyticsService } from './types';

/**
 * Alerts Service
 */
export class AnalyticsService implements IAnalyticsService {
  /**
   * The app insights instance
   */
  appInsights!: ApplicationInsights;
  /**
   * Initialize and start analytic service.
   */
  start(): void {
    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: '89ae5e6a-9907-4676-92e2-b5181b4dc319',
      },
    });
  }

  /**
   * Track a page view (page display).
   * @param name - page name
   * @param url - page url
   * @param properties - Object with String properties (additional data, ex : { prop1: 'prop1', prop2: 'prop2' })
   * @param measurements - Object with number properties (metrics associated, ex : { measurement1: 1 })
   */
  trackPageView(
    name: string,
    url = '',
    properties: Record<string, string> = {},
    measurements: Record<string, number> = {}
  ): void {
    this.appInsights.trackPageView({
      name,
      uri: url,
      properties: { ...properties, ...measurements },
    });
  }

  /**
   * Start tracking a page view (starts the timer, 'stopTrackPage' call is needed).
   * @param name - page name
   */
  startTrackPage(name: string): void {
    this.appInsights.startTrackPage(name);
  }

  /**
   * Stop tracking a page view (Stops the timer that was started by calling 'startTrackPage')
   * @param name - page name
   * @param url - page url
   * @param properties - Object with String properties (additional data, ex : { prop1: 'prop1', prop2: 'prop2' })
   * @param measurements - Object with number properties (metrics associated, ex : { measurement1: 1 })
   */
  stopTrackPage(
    name: string,
    url: string,
    properties: Record<string, string>,
    measurements: Record<string, number>
  ): void {
    this.appInsights.stopTrackPage(name, url, properties, measurements);
  }

  /**
   * Track an event.
   * @param name - Event name
   * @param properties - Object with String properties (additional data, ex : { prop1: 'prop1', prop2: 'prop2' })
   * @param measurements - Object with number properties (metrics associated, ex : { measurement1: 1 })
   */
  trackEvent(
    name: string,
    properties: Record<string, string> = {},
    measurements: Record<string, number> = {}
  ): void {
    this.appInsights.trackEvent({ name }, { ...properties, ...measurements });
  }

  /**
   * Track a metric positive numeric value.
   * @param name - Metric name
   * @param value - Metric value
   * @param properties - Object with String properties (additional data, ex : { prop1: 'prop1', prop2: 'prop2' })
   */
  trackMetric(name: string, value: number, properties: Record<string, string>): void {
    this.appInsights.trackMetric({ name, average: value }, properties);
  }

  /**
   * Track an exception.
   * @param error - An Error from a catch clause
   * @param handleAt - Error handle at indicator (default 'unhandled')
   * @param properties - Object with String properties (additional data, ex : { prop1: 'prop1', prop2: 'prop2' })
   * @param measurements - Object with number properties (metrics associated, ex : { measurement1: 1 })
   */
  trackException(
    error: Error,
    handleAt = 'unhandled',
    properties: Record<string, string>,
    measurements: Record<string, number>
  ): void {
    this.appInsights.trackException(
      { id: handleAt, exception: error },
      { ...properties, ...measurements }
    );
  }

  /**
   * Track a trace message.
   * @param message - Trace message
   * @param properties - Object with String properties (additional data, ex : { prop1: 'prop1', prop2: 'prop2' })
   */
  trackTrace(message: string, properties: Record<string, string>): void {
    this.appInsights.trackTrace({ message }, properties);
  }
}

import { ROUTE_NAME_404 } from '../constants/routes';

const routes = [
  {
    path: '/',
    name: 'applications',
    component: () => import('../views/Applications'),
    meta: {
      docUrl: null,
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login'),
    meta: {
      publicRoute: true,
    },
  },
  {
    path: '/browser-version',
    name: 'browserVersionError',
    component: () => import('../views/errors/BrowserVersion'),
    meta: {
      publicRoute: true,
    },
  },
  {
    path: '/application-error',
    name: 'applicationError',
    component: () => import('../views/errors/Error'),
    meta: {
      publicRoute: true,
    },
  },
  {
    path: '/settings-error',
    name: 'settingsError',
    component: () => import('../views/errors/SettingsError'),
    meta: {
      publicRoute: true,
    },
  },
  {
    path: '/mobility-success',
    name: 'mobilitySuccess',
    component: () => import('../views/mobility/MobilityLoginSuccess'),
    meta: {
      publicRoute: false,
    },
  },
  {
    // Not found handler
    path: '*',
    name: ROUTE_NAME_404,
    component: () => import('../views/errors/404'),
    meta: {
      publicRoute: true,
    },
  },
];

export default routes;
